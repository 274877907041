import isEmpty from "../validation/is-empty";

import {
  SET_CURRENT_USER,
  SET_COTIZACION,
  SET_LEAD,
  ADD_PAQUETE,
  GET_PAQUETE,
  GET_PAQUETE_ID,
  GET_PAQUETES_RECIENTES,
  GET_RESULTADOS,
  LOADING,
  GET_LEADS,
  GET_COTIZACIONES,
  ADD_POST,
  GET_POST,
  GET_POST_ID,
  GET_CALIFICACIONES,
  GET_VIAJES_INTERESANTES,
  ADD_CHECKOUT,
  GET_CHECKOUT,
} from "../actions/types";

const initialState = {
  checkouts: [],
  checkout: {},
  cotizacion: {},
  lead: {},
  paquetes: [],
  paquete: {},
  posts: [],
  post: {},
  paquetesRecientes: [],
  resultados: [],
  loading: false,
  leads: [],
  cotizaciones: [],
  calificaciones: [],
  otrosPaquetes: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case SET_COTIZACION:
      return {
        ...state,
        cotizacion: action.payload,
      };

    case GET_VIAJES_INTERESANTES:
      return {
        ...state,
        otrosPaquetes: action.payload,
      };
    case SET_LEAD:
      return {
        ...state,
        lead: action.payload,
      };

    case GET_CHECKOUT:
      return {
        ...state,
        checkout: action.payload,
      };

    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_COTIZACIONES:
      return {
        ...state,
        cotizaciones: action.payload,
      };

    case GET_CALIFICACIONES:
      return {
        ...state,
        calificaciones: action.payload,
      };
    case GET_LEADS:
      return {
        ...state,
        leads: action.payload,
      };

    case ADD_PAQUETE:
      if (action.payload && action.payload._id)
        return {
          ...state,
          paquetes: [...state.paquetes, action.payload],
        };

      return {
        ...state,
        paquetes: action.payload,
      };

    case ADD_CHECKOUT:
      if (action.payload && action.payload._id)
        return {
          ...state,
          checkouts: [...state.checkouts, action.payload],
        };

      return {
        ...state,
        checkouts: action.payload,
      };

    case ADD_POST:
      if (action.payload && action.payload._id)
        return {
          ...state,
          posts: [...state.posts, action.payload],
        };

      return {
        ...state,
        posts: action.payload,
      };

    case GET_PAQUETE:
      return {
        ...state,
        paquetes: action.payload,
      };
    case GET_POST:
      return {
        ...state,
        posts: action.payload,
      };
    case GET_PAQUETES_RECIENTES:
      return {
        ...state,
        paquetesRecientes: action.payload,
      };
    case GET_PAQUETE_ID:
      return {
        ...state,
        paquete: action.payload,
      };

    case GET_POST_ID:
      return {
        ...state,
        post: action.payload,
      };
    case GET_RESULTADOS:
      return {
        ...state,
        resultados: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}

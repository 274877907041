import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";

import azureReducer from "./azureReducer";
import stripeReducer from "./stripeReducer";
import travelReducer from "./travelReducer";
import catalogoReducer from "./catalogoReducer";

const appReducer = combineReducers({
  auth: authReducer,
  errors: errorReducer,
  azure: azureReducer,
  stripe: stripeReducer,
  travel: travelReducer,
  catalogos: catalogoReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGGED_OUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;

import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  UPLOADING_FILE,
  CHANGE_AVATAR,
  USER_LOGGED_OUT,
  CONFIRM_EMAIL,
  ISNOT_UPLOADING,
  SENT_EMAIL,
  UPDATE_PROGRESS,
  UPDATED_PASSWORD,
  GET_INVITATIONINFO,
  SENT_RECOVERYEMAIL,
  CONFIRM_TOKEN,
} from "./types";

// Register User
export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post("/api/users/register", userData)
    .then((res) => {
      axios.post("/api/users/login", userData).then((res) => {
        // Save to localStorage
        const { token } = res.data;
        // Set token to ls
        localStorage.setItem("jwtToken", token);
        // Set token to Auth header
        setAuthToken(token);
        // Decode token to get user data
        const decoded = jwt_decode(token);
        // Set current user
        dispatch(setCurrentUser(decoded));
      });
    })
    .catch((err) => {
      if (err.response && err.response.data)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
    });
};

// Update User
export const updateUser = (userData) => (dispatch) => {
  axios
    .post("/api/users/update", userData)
    .then((res) => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Login - Get User Token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post("/api/users/login", userData)
    .then((res) => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) => {
      if (err.response !== undefined)
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
    });
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  dispatch({ type: USER_LOGGED_OUT });
};

//TODO: Cargar foto de perfil en caso de tener
// Cambiar foto de perfil

export const uploadFile = (formData) => (dispatch) => {
  dispatch({
    type: UPLOADING_FILE,
  });

  axios
    .post("/api/profile/upload", formData)
    .then((result) => {
      dispatch({
        type: CHANGE_AVATAR,
        payload: result.data.avatar,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// get current profile picture
export const getProfilePicture = () => (dispatch) => {
  axios
    .post("/api/profile/profilePicture")
    .then((res) => {
      dispatch({ type: CHANGE_AVATAR, payload: res.data.avatar });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Login - Get User Token
export const changePassword = (userData) => (dispatch) => {
  dispatch({
    type: UPDATED_PASSWORD,
    payload: false,
  });

  axios
    .post("/api/users/changePassword", userData)
    .then((res) => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user

      dispatch({
        type: UPDATED_PASSWORD,
        payload: true,
      });
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });

      dispatch(setCurrentUser(decoded));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const confirmEmail = (token) => (dispatch) => {
  dispatch({
    type: UPLOADING_FILE,
    payload: 0,
  });

  axios
    .get("/api/users/confirmation/" + token)
    .then((result) => {
      dispatch({
        type: CONFIRM_EMAIL,
      });
    })
    .catch((err) => {
      dispatch({ type: ISNOT_UPLOADING });
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const sendConfirmationEmail = () => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post(
      "/api/users/confirmEmail",
      {},
      {
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader("content-length") ||
              progressEvent.target.getResponseHeader(
                "x-decompressed-content-length"
              );

          if (totalLength !== null) {
            dispatch({
              type: UPDATE_PROGRESS,
              payload: Math.round((progressEvent.loaded * 100) / totalLength),
            });
          }
        },
      }
    )
    .then((result) => {
      dispatch({
        type: SENT_EMAIL,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({ type: ISNOT_UPLOADING });
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getInvitationInfo = (token) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/users/getInvitationInfo", token, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((result) => {
      console.log(result);
      dispatch({
        type: GET_INVITATIONINFO,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const sendRecoveryEmail = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  dispatch({
    type: SENT_RECOVERYEMAIL,
    payload: false,
  });

  axios
    .post("/api/users/recoveryEmail", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((result) => {
      dispatch({
        type: SENT_RECOVERYEMAIL,
        payload: result.data.enviado,
      });
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const validateToken = (token) => (dispatch) => {
  dispatch({
    type: UPLOADING_FILE,
  });

  axios
    .get("/api/users/confirmToken/" + token)
    .then((result) => {
      dispatch({
        type: CONFIRM_TOKEN,
        payload: result.data.success,
      });
      dispatch({ type: ISNOT_UPLOADING });
    })
    .catch((err) => {
      dispatch({ type: ISNOT_UPLOADING });
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updatePassword = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROGRESS,
    payload: 0,
  });

  axios
    .post("/api/users/updatePassword", data, {
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );

        if (totalLength !== null) {
          dispatch({
            type: UPDATE_PROGRESS,
            payload: Math.round((progressEvent.loaded * 100) / totalLength),
          });
        }
      },
    })
    .then((res) => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

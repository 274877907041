import {
  SET_CONTINENTE,
  SET_PAIS,
  SET_CIUDAD,
  SET_TIPO_DE_PAQUETE,
  SET_CATEGORIA_DE_PAQUETE,
  GET_CONTINENTE,
  GET_PAIS,
  GET_CIUDAD,
  GET_TIPO_DE_PAQUETE,
  GET_CATEGORIA_DE_PAQUETE,
  GET_PAIS_CONTINENTES,
  GET_CIUDAD_PAISES,
} from "../actions/types";

const initialState = {
  continentes: [],
  paises: [],
  ciudades: [],
  tiposDePaquete: [],
  categoriasDePaquete: [],
  paisesContinentes: [],
  ciudadesPaises: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CONTINENTE:
      return {
        ...state,
        continentes: action.payload,
      };

    case SET_CONTINENTE:
      if (action.payload && action.payload._id)
        return {
          ...state,
          continentes: [...state.continentes, action.payload],
        };

      return {
        ...state,
        continentes: action.payload,
      };
    case SET_PAIS:
      if (action.payload && action.payload._id)
        return {
          ...state,
          paises: [...state.paises, action.payload],
        };

      return {
        ...state,
        paises: action.payload,
      };

    case SET_CIUDAD:
      if (action.payload && action.payload._id)
        return {
          ...state,
          ciudades: [...state.ciudades, action.payload],
        };

      return {
        ...state,
        ciudades: action.payload,
      };

    case SET_TIPO_DE_PAQUETE:
      if (action.payload && action.payload._id)
        return {
          ...state,
          tiposDePaquete: [...state.tiposDePaquete, action.payload],
        };

      return {
        ...state,
        tiposDePaquete: action.payload,
      };

    case SET_CATEGORIA_DE_PAQUETE:
      if (action.payload && action.payload._id)
        return {
          ...state,
          categoriasDePaquete: [...state.categoriasDePaquete, action.payload],
        };

      return {
        ...state,
        categoriasDePaquete: action.payload,
      };

    case GET_PAIS:
      return {
        ...state,
        paises: action.payload,
      };

    case GET_CIUDAD:
      return {
        ...state,
        ciudades: action.payload,
      };

    case GET_PAIS_CONTINENTES:
      return {
        ...state,
        paisesContinentes: action.payload,
      };

    case GET_CIUDAD_PAISES:
      return {
        ...state,
        ciudadesPaises: action.payload,
      };

    case GET_TIPO_DE_PAQUETE:
      return {
        ...state,
        tiposDePaquete: action.payload,
      };

    case GET_CATEGORIA_DE_PAQUETE:
      return {
        ...state,
        categoriasDePaquete: action.payload,
      };

    default:
      return state;
  }
}
